//
//
//
//
//

import OrderList from "../index.vue";
export default {
  data() {
    return {
      statuList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 52,
          label: "退款成功"
        },
        {
          value: 53,
          label: "退款失败"
        }
      ]
    };
  },
  components: {
    OrderList
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
